
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Defina a rota principal (página inicial) do seu aplicativo */}
        <Route path="/" element={<App />} />

        {/* Redirecionar todas as outras URLs para a página principal */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;