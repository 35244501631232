// src/components/Navbar.js

import React, { useEffect } from 'react';
import './Navbar.css'; // Importe o arquivo de estilo CSS

const Navbar = () => {
    function redirect() {
        document.location.href = 'http://key4play.com';
      }

    function countdownTimer() {
        var el = document.getElementById('seconds'),
          total = el.innerHTML,
          timeinterval = setInterval(function () {
            total = --total;
            el.textContent = total;
            if (total <= 0) {
              clearInterval(timeinterval);
              redirect()
            }
          }, 1000);
      }

      useEffect(() => {
countdownTimer();
      }, []);
    
  return (
    <div className="navbar">
       <p className="textColor2">
                    Você será automaticamente redirecionado <span id="seconds" className="sec">20</span> segundos.   
                    <button className="button" onClick={() => redirect()}>
                      Clique aqui
                    </button>  </p>
    </div>
  );
};

export default Navbar;
