import logo from './xpass.png';
import k4 from './k4play.png';
import './App.css';
import { useEffect, useState } from 'react';
import bg from './bg.jpg';
import Navbar from './Navbar.js';

function App() {
  const [backgroundPosition, setBackgroundPosition] = useState(10);

  function redirect() {
    document.location.href = 'http://key4play.com';
  }

  
  useEffect(() => {
    const moveBackground = () => {
      setBackgroundPosition((prevPosition) => prevPosition - 1);
    };

    const interval = setInterval(moveBackground, 80); // Adjust the carousel speed here

    return () => clearInterval(interval);
  }, []);

  const style = {
    background: `
      linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.9)),
      url(${bg}) 0 ${backgroundPosition}px repeat-y`, // Repeat the image vertically
    backgroundSize: 'cover', // Adjust the background image size to cover the container
  };

  return (
    <div className="App">
      <Navbar />
      <div className="content-container">
        <header className="App-header">
          <div className="carousel-container">
            <div className="carousel-content" style={style}>
            <div className="imgcontent">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={logo} className="xpass" alt="XPass Logo" />
                  <div className="arrow" style={{ margin: '20px 20px 0px 20px' }}>
                  <svg
                    className="arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                   
                  >
                    <line x1="0" y1="12" x2="24" y2="12" />
                    <polyline points="18 6 24 12 18 18" />
                  </svg>
                </div>
                  <img src={k4} className="k4" alt="K4Play Logo" style={{ marginLeft: '20px' }} />
                </div>
               
              </div>
              <div className="First">
                <div className="textOne">
                <p>
                  Prezado(a) visitante,<br />
                  <p className="textColor">
                    Gostaríamos de informar que passamos por uma emocionante transformação e agora somos o Key4Play!
                    <br />
                    <br />
                    Anteriormente conhecidos como xpassultimate, decidimos evoluir e aprimorar nossa marca para oferecer a você a melhor experiência em aquisição de chaves de jogos para diversas plataformas.
                    <br />
                  </p>
                  Nossa paixão por proporcionar acesso rápido e seguro aos seus jogos favoritos continua inabalável. Com a mudança para Key4Play, prometemos a mesma excelência no serviço que você conhecia, com uma pitada de novidades emocionantes.
                  <br />
                  <br />
                  Agradecemos sua confiança contínua em nossa plataforma e esperamos que desfrute de cada momento com o Key4Play.
               
                  <br /><br />
                    Se você tiver alguma dúvida ou precisar de assistência, não hesite em entrar em contato conosco. Estamos aqui para ajudar!
                    <br />Whastapp: (027) 98127-6651<br /><br />
                  Atenciosamente,
                  <br />
                  Equipe Key4Play
                  <br />

            
                </p>
      
                </div>
            
              </div>
              <div style={{height: '150px'}}/>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default App;